<template>
  <div>
    <b-tabs pills>
      <b-tab title="Modelo(s) de Documento(s)" active>
        <b-card-text>
          <b-card-code no-body>
            <b-card-body>
              <b-row>
                <b-col md="2">
                  <b-form-group label="Pesquisar Por" label-for="opcao">
                    <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Descrição" label-for="descricao">
                    <b-form-input
                      id="descricao"
                      placeholder="Descrição"
                      v-model="pesquisar.descricao"
                      v-on:keydown.enter="carregarGrid"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="auto">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-top: 20px; width: 160px"
                    @click="pesquisarDocumento()"
                    :disabled="!acesso.ConfiguracoesModelosConsultar"
                  >
                    Pesquisar
                  </b-button>
                </b-col>
                <b-col md="auto" align="right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-top: 20px; width: 160px"
                    @click="novoRegistro()"
                    :disabled="!acesso.ConfiguracoesModelosCriarNovo"
                  >
                    Novo Cadastro
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
            <b-table
              v-show="items.length > 0"
              striped
              responsive
              class="position-relative"
              :per-page="pesquisar.perPage"
              :items="items"
              :fields="fields"
              :busy="isBusy"
              :current-page="pesquisar.currentPage"
            >
              <template v-slot:table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-1"></b-spinner>
                  <strong>Carregando...</strong>
                </div>
              </template>

              <template #cell(opcoes)="row">
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      title="Este botão contém as opções disponíveis para este registro"
                      :disabled="!acesso.ConfiguracoesModelosExcluir"
                    >
                      <feather-icon icon="SettingsIcon" />
                    </b-button>
                  </template>
                  <b-dropdown-item @click="excluir(row.item.id)">
                    <feather-icon icon="XIcon" size="16" />
                    <span class="align-middle ml-50">Excluir</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>

              <template #cell(originalName)="row">
                <b-link class="fileLinkAnchor" ref="#" :disabled="isDownloadBusy" @click="downloadArquivo(row.item)">
                  {{ row.item.originalName }}
                </b-link>
              </template>
            </b-table>

            <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                style="align-items: center"
                label="Por Página"
                label-cols="6"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
              </b-form-group>

              <!-- pagination -->
              <b-pagination
                v-model="pesquisar.currentPage"
                :total-rows="totalRows"
                :per-page="pesquisar.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-card-body>

            <!-- Modal Cadastro -->
            <b-modal id="modal-cadastro" ref="modal-cadastro" centered title="Cadastro" no-close-on-backdrop>
              <validation-observer ref="formOrgao">
                <b-form>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Nome do Documento" label-for="nome">
                        <validation-provider #default="{ errors }" name="Nome do Documento" rules="required">
                          <b-form-input id="nome" type="text" placeholder="Nome do Documento" v-model="dados.nome" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-file
                        ref="file"
                        accept=".docx"
                        placeholder="Escolha um arquivo"
                        drop-placeholder="Deixe seu arquivo aqui..."
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
              <!-- <template #modal-footer="{ hide, cancel, ok }"> -->
              <template #modal-footer="{}">
                <b-button variant="outline-secondary" @click="$bvModal.hide('modal-cadastro')"> Fechar </b-button>
                <b-button variant="primary" @click="salvar" :disabled="loading">
                  {{ !loading ? 'Salvar' : '' }}
                  <b-spinner small v-if="loading" />
                </b-button>
              </template>
            </b-modal>
          </b-card-code>
        </b-card-text>
      </b-tab>
      <b-tab title="Contrato(s) Check-List">
        <b-card-text>
          <ConfiguracoesSistemaCheckList v-if="options" :general-data="options" />
        </b-card-text>
      </b-tab>
      <b-tab title="Tag(s)">
        <b-card-text>
          <Tag />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import downloadFileAWS from '@/@core/utils/Download-File-AWS.ts'
  import ConfiguracoesSistemaCheckList from './configuracoesSistema/ConfiguracoesSistemaCheckList.vue'
  import Tag from './tag.vue'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      ConfiguracoesSistemaCheckList,
      Tag,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        options: null,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        isDownloadBusy: false,
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 10, currentPage: 1 },
        required,
        tipoOpcoes: [{ value: 1, text: 'Nome' }],
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'nome', label: 'Nome', sortable: true },
          { key: 'originalName', label: 'Documento' },
        ],
        items: [],
        dados: {},
        loading: false,
        acesso: {
          ConfiguracoesModelosConsultar: false,
          ConfiguracoesModelosCriarNovo: false,
          ConfiguracoesModelosExcluir: false,
        },
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
      ambiente() {
        return process.env.VUE_APP_BASE_URL
      },
    },
    async mounted() {
      await this.carregarDadosInstituto()
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesModelosConsultar = true
        this.acesso.ConfiguracoesModelosCriarNovo = true
        this.acesso.ConfiguracoesModelosExcluir = true
      } else {
        this.acesso.ConfiguracoesModelosConsultar = this.userData.GrupoAcesso.ConfiguracoesModelosConsultar
        this.acesso.ConfiguracoesModelosCriarNovo = this.userData.GrupoAcesso.ConfiguracoesModelosCriarNovo
        this.acesso.ConfiguracoesModelosExcluir = this.userData.GrupoAcesso.ConfiguracoesModelosExcluir
      }
      await this.carregarGrid()
    },
    methods: {
      async pesquisarDocumento() {
        this.pesquisar = { ...this.pesquisar, currentPage: 1 }
        await this.carregarGrid()
      },
      async carregarGrid() {
        this.isBusy = true

        await useJwt
          .pesquisar('cadastro/documentoModelo/Getpesquisar', {
            ...this.pesquisar,
            institutoSelecionado: this.userData.institutoSelecionado,
          })
          .then((response) => {
            this.items = response.data
            this.totalRows = this.items.length

            this.items = this.items.sort((current, next) => current.nome.localeCompare(next.nome))
          })
          .catch((error) => {
            console.error(error)
          })

        this.isBusy = false
      },
      async carregarDadosInstituto() {
        await useJwt
          .get(`cadastro/instituto/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.options = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
      novoRegistro() {
        this.dados = {}
        this.$refs['modal-cadastro'].show()
      },
      excluir(item) {
        let filtro = {
          id: item,
          institutoSelecionado: this.userData.institutoSelecionado,
        }

        useJwt.post(`cadastro/documentoModelo/GetdocumentoModeloCheckList`, filtro).then((response) => {
          if (response.data.length > 0)
            this.msgError('Exclusão não permitida! O documento selecionado está vinculado a um check-list.')
          else {
            this.$swal({
              title: 'Atenção!',
              text: 'Deseja realmente excluir o documento selecionado?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sim!',
              cancelButtonText: 'Não!',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                useJwt
                  .delete(`cadastro/documentoModelo`, item)
                  .then((response) => {
                    this.carregarGrid()
                  })
                  .catch((error) => {
                    console.error(error)
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: error.response.data.error,
                          icon: 'BellIcon',
                          variant: 'danger',
                        },
                      },
                      {
                        position: 'top-right',
                        timeout: 8000,
                      },
                    )
                  })
              }
            })
          }
        })
      },
      editar(item) {
        useJwt
          .edit(`cadastro/documentoModelo`, item)
          .then((response) => {
            this.dados = response.data
            this.$refs['modal-cadastro'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      salvar(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formOrgao.validate().then((success) => {
          if (success) {
            this.loading = true
            if (!this.dados.id) {
              const formData = new FormData()
              formData.append('nome', this.dados.nome)
              formData.append('institutoId', this.userData.institutoSelecionado)
              formData.append('file', this.$refs.file.files[0])

              useJwt
                .postDocumento(`cadastro/documentoModelo`, (event) => {}, formData)
                .then((response) => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Documento salvo com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((result) => {
                    if (result.value) {
                      this.loading = !this.loading
                      this.$refs['modal-cadastro'].hide()
                      this.carregarGrid()
                    }
                  })
                })
                .catch((error) => {
                  console.error(error)
                  this.loading = !this.loading
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Atenção!',
                        text: error.response.data.error || 'Erro ao anexar o documento.',
                        icon: 'BellIcon',
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'top-right',
                      timeout: 8000,
                    },
                  )
                })
            }
          }
        })
      },
      async downloadArquivo(item) {
        const s3Key = item.s3Key
        const fileName = item.originalName
        this.isDownloadBusy = true
        await downloadFileAWS(s3Key, fileName)
          .then((response) => {
            if (response) {
              this.$message.success('Download realizado com sucesso!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao realizar o download!')
          })
          .finally(() => {
            this.isDownloadBusy = false
          })
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      msgSuccess(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'UserCheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
    },
  }
</script>

<style scoped>
  .fileLinkAnchor {
    cursor: pointer;
    color: #7367f0;
  }

  .fileLinkAnchor.disabled {
    cursor: wait;
  }
</style>
