<template>
  <b-card>
    <validation-observer ref="formCheckList">
      <b-form>
        <b-row>
          <b-col sm="3">
            <b-form-group label="Descrição" label-for="Descrição">
              <validation-provider #default="{ errors }" name="Descrição" rules="required">
                <b-form-input id="DescricaoChecklist" v-model="dados.nome" placeholder="Descrição" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <div class="demo-inline-spacing">
              <b-form-checkbox v-model="dados.Efetivo">Efetivo</b-form-checkbox>
              <b-form-checkbox v-model="dados.Estavel">Estável</b-form-checkbox>
              <b-form-checkbox v-model="dados.Pensionista">Pensionista</b-form-checkbox>
              <b-form-checkbox v-model="dados.Aposentado">Aposentado</b-form-checkbox>
            </div>
          </b-col>
          <b-col cols="3">
            <b-button
              style="width: 160px"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-2 mr-1"
              @click.prevent="limpar"
            >
              Limpar
            </b-button>
            <b-button
              style="width: 160px"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2"
              @click.prevent="salvar"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-table
              striped
              class="responsive"
              :per-page="pesquisar.perPage"
              :items="items"
              :fields="fields"
              :busy="isBusy"
              :current-page="pesquisar.currentPage"
            >
              <template v-slot:table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-1"></b-spinner>
                  <strong>Carregando...</strong>
                </div>
              </template>
              <template #cell(opcoes)="row">
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      title="Este botão contém as opções disponíveis para este registro"
                    >
                      <feather-icon icon="SettingsIcon" />
                    </b-button>
                  </template>
                  <b-dropdown-item @click="editar(row.item.id)">
                    <feather-icon icon="EditIcon" size="16" />
                    <span class="align-middle ml-50">Editar nome</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="excluir(row.item.id)">
                    <feather-icon icon="XIcon" size="16" />
                    <span class="align-middle ml-50">Excluir</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="parametro(row.item.id)">
                    <feather-icon icon="CpuIcon" size="16" />
                    <span class="align-middle ml-50">Check-list</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template #cell(categoria)="row">
                <span v-for="code in row.item.categorias" :key="code.id">
                  <b-badge pill variant="primary" class="mr-1">
                    {{ code.categoria }}
                  </b-badge>
                </span>
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                style="align-items: center"
                label="Por Página"
                label-cols="6"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
              </b-form-group>

              <!-- pagination -->
              <b-pagination
                v-model="pesquisar.currentPage"
                :total-rows="totalRows"
                :per-page="pesquisar.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-card-body>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal id="modal-parametro" ref="modal-parametro" centered title="Parâmetro" no-close-on-backdrop hide-footer>
      <validation-observer ref="formParametro">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-card title="Check-List">
                <b-row>
                  <b-col md="12">
                    <div v-for="(item, index) in itemsDocumentoCheckList" :key="item.id" class="d-flex justify-content-start">
                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-primary"
                        class="btn-icon"
                        @click.prevent="parametrizarDocumentoCheckList(item)"
                        title="Parâmetrizar Documento"
                      >
                        <feather-icon icon="SettingsIcon" />
                      </b-button>
                      <b-form-checkbox :key="index" :value="item" v-model="itemDocumentoSelecionado" style="margin-top: 5px">
                        {{ item.documento.nome }}
                      </b-form-checkbox>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      @click.prevent="removerSelecionado"
                    >
                      Remover Selecionado(s)
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col md="6"
              ><validation-observer ref="formNovoDocumento">
                <b-form>
                  <b-card title="Documentos para Incluir">
                    <b-card-text>
                      <b-row>
                        <b-col md="7">
                          <b-form-group label="Descrição" label-for="descricao">
                            <validation-provider #default="{ errors }" name="Descrição" rules="required">
                              <b-form-input
                                id="descricao"
                                type="text"
                                placeholder="Descrição"
                                v-model="dadosNovoDocumento.nome"
                                class="form-control"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="5">
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                            class="mt-2 mr-1"
                            @click.prevent="limparNovoDocumento"
                          >
                            Limpar
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-2"
                            @click.prevent="novoDocumento"
                          >
                            Salvar
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <div v-for="(item, index) in itemsDocumento" class="d-flex justify-content-start" :key="item.id">
                            <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                              <template #button-content>
                                <b-button
                                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                  variant="flat-primary"
                                  class="btn-icon"
                                  title="Este botão contém as opções disponíveis para este registro"
                                >
                                  <feather-icon icon="SettingsIcon" />
                                </b-button>
                              </template>
                              <b-dropdown-item @click="editarDocumento(item)">
                                <feather-icon icon="EditIcon" size="16" />
                                <span class="align-middle ml-50">Editar</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click.prevent="excluirDocumento(item.id)">
                                <feather-icon icon="XIcon" size="16" />
                                <span class="align-middle ml-50">Excluir</span>
                              </b-dropdown-item>
                            </b-dropdown>
                            <b-form-checkbox :key="index" :value="item" v-model="documentoSelecionado" style="margin-top: 5px">
                              {{ item.nome }}
                            </b-form-checkbox>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <b-col md="12">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            size="sm"
                            @click.prevent="incluirSelecionado"
                            class="mr-1"
                          >
                            Incluir Selecionado(s)
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-parametro-documento"
      ref="modal-parametro-documento"
      centered
      :title="'Parâmetro Documento - ' + itemSelecionadoDocumentoTitulo"
      no-close-on-backdrop
      @ok.prevent="salvarParametroDocumento"
    >
      <validation-observer ref="formParametroDocumento">
        <b-form>
          <b-row>
            <b-col md="12">
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="dadosSelecionadoDocumento.documentoEmitido"
                  name="radioDocumentoEmitido"
                  value="true"
                  class="custom-control-primary"
                >
                  Documento Emitido
                </b-form-radio>
                <b-form-radio
                  v-model="dadosSelecionadoDocumento.documentoEmitido"
                  name="radioDocumentoEmitido"
                  value="false"
                  class="custom-control-primary"
                  @change="limparItemsModeloDocumento"
                >
                  Documento Upload
                </b-form-radio>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col md="12">
              <b-form-select
                v-model="dadosSelecionadoDocumento.documentoSelecionado"
                :options="itemsModeloDocumento"
                multiple
                value-field="id"
                text-field="nome"
                :disabled="dadosSelecionadoDocumento.documentoEmitido == false"
              />
              <small>'Ctrl' para selecionar mais de uma documento</small>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import jwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: true,
        dados: {},
        dadosNovoDocumento: {},
        dadosSelecionadoDocumento: {
          documentoEmitido: null,
          documentoSelecionado: [],
        },
        itemSelecionado: {},
        itemSelecionadoDocumento: {},
        itemSelecionadoDocumentoTitulo: {},
        items: [],
        itemsDocumento: [],
        itemsDocumentoCheckList: [],
        itemsModeloDocumento: [],
        documentoSelecionado: [],
        itemDocumentoSelecionado: [],
        pesquisar: {
          perPage: 10,
          currentPage: 1,
        },
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        fields: [{ key: 'opcoes', label: 'Opções' }, { key: 'nome', label: 'Nome', sortable: true }, 'categoria'],
      }
    },
    mounted() {
      this.carregarGrid()
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        useJwt
          .get(`checkList/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.isBusy = !this.isBusy
            this.items = response.data
            this.totalRows = this.items.length

            this.items = this.items.sort((current, next) => current.nome.localeCompare(next.nome))
          })
          .catch((error) => {
            console.error(error)
          })
      },
      limpar() {
        this.dados = {}
      },
      editar(item) {
        useJwt
          .edit(`checklist/editCheckList`, item)
          .then((response) => {
            this.dados = response.data

            this.dados = {
              ...this.dados,
              Efetivo: this.dados?.categorias.filter((f) => f.categoria == 'Efetivo').length > 0,
              Estavel: this.dados?.categorias.filter((f) => f.categoria == 'Estável').length > 0,
              Pensionista: this.dados?.categorias.filter((f) => f.categoria == 'Pensionista').length > 0,
              Aposentado: this.dados?.categorias.filter((f) => f.categoria == 'Aposentado').length > 0,
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
      salvar(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formCheckList.validate().then((success) => {
          if (success) {
            this.loading = true

            let categoria = []

            if (this.dados.Efetivo) categoria.push('Efetivo')
            if (this.dados.Estavel) categoria.push('Estável')
            if (this.dados.Pensionista) categoria.push('Pensionista')
            if (this.dados.Aposentado) categoria.push('Aposentado')

            this.dados = {
              ...this.dados,
              institutoId: this.userData.institutoSelecionado,
              categoria,
            }

            if (this.dados.id == undefined) {
              useJwt
                .post(`checklist/postCheckList`, this.dados)
                .then((response) => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Cadastrado com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((result) => {
                    if (result.value) {
                      this.loading = !this.loading
                      this.dados = {}
                      this.carregarGrid()
                    }
                  })
                })
                .catch((error) => {
                  console.error(error)
                  this.loading = !this.loading
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: error.response.data.error,
                        icon: 'BellIcon',
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'top-right',
                      timeout: 8000,
                    },
                  )
                })
            } else {
              useJwt
                .update(`checklist/putCheckList`, this.dados.id, this.dados)
                .then((response) => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Registro alterado com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((result) => {
                    if (result.value) {
                      this.loading = !this.loading
                      this.dados = {}
                      this.carregarGrid()
                    }
                  })
                })
                .catch((error) => {
                  this.loading = !this.loading
                  console.error(error)
                })
            }
          }
        })
      },
      excluir(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir o cadastro selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete(`checklist/excluirCheckList`, item)
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erro: Registro possui relacionamentos!',
                      icon: 'BellIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-right',
                    timeout: 8000,
                  },
                )
              })
          }
        })
      },
      carregarParametroCheckList() {
        useJwt
          .get(`cadastro/instituto/parametroCheckList/${this.itemSelecionado}`)
          .then((response) => {
            this.itemsDocumentoCheckList = response.data.checklist
            this.itemsDocumento = response.data.documento
          })
          .catch((error) => {
            console.error(error)
          })
      },
      parametro(item) {
        this.itemSelecionado = item
        this.carregarParametroCheckList()
        this.$refs['modal-parametro'].show()
      },
      incluirSelecionado() {
        let dados = {
          checkListId: this.itemSelecionado,
          documento: this.documentoSelecionado.map((m) => m.id),
        }

        useJwt
          .post(`checklist/postDocumentoCheckList`, dados)
          .then((response) => {
            this.documentoSelecionado = []
            this.itemDocumentoSelecionado = []
            this.carregarParametroCheckList()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      removerSelecionado() {
        const parametro = this.itemDocumentoSelecionado.map((documento) => documento.id)
        useJwt
          .post(`checklist/excluirDocumentoCheckList`, parametro)
          .then((response) => {
            this.documentoSelecionado = []
            this.itemDocumentoSelecionado = []
            this.carregarParametroCheckList()
          })
          .catch((error) => {})
      },
      parametrizarDocumentoCheckList(item) {
        this.itemSelecionadoDocumento = item
        this.itemSelecionadoDocumentoTitulo = item.documento.nome

        useJwt
          .post(`cadastro/instituto/GetcarregarParametroDocumento`, {
            documentoCheckListParametro: item.id,
            institutoId: this.userData.institutoSelecionado,
          })
          .then((response) => {
            this.itemsModeloDocumento = response.data.documento

            let docsSelected = []

            if (response.data.parametro) {
              response.data.parametro.documentoModelo.map((item) => {
                docsSelected.push(item.id)
              })
              this.dadosSelecionadoDocumento = {
                ...response.data.parametro,
                documentoEmitido: response.data.parametro.documentoEmitido.toString(),
                documentoSelecionado: docsSelected,
              }
            } else {
              this.dadosSelecionadoDocumento = {
                documentoEmitido: false,
                documentoSelecionado: [],
              }
            }
            this.$refs['modal-parametro-documento'].show()
          })
          .catch((error) => {})
      },
      limparItemsModeloDocumento() {
        this.dadosSelecionadoDocumento.documentoSelecionado = []
      },
      salvarParametroDocumento() {
        if (this.dadosSelecionadoDocumento.documentoEmitido == undefined) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Selecione uma opção',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        if (
          this.dadosSelecionadoDocumento.documentoEmitido === 'true' &&
          this.dadosSelecionadoDocumento.documentoSelecionado.length == 0
        ) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Selecione uma documento',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        // TODO: revisar o código abaixo
        jwt
          .post(`cadastro/instituto/salvarParametroDocumento`, {
            ...this.dadosSelecionadoDocumento,
            documentoEmitido: this.dadosSelecionadoDocumento.documentoEmitido === 'true',
            documentoCheckListId: this.itemSelecionadoDocumento.id,
          })
          .then((response) => {
            this.$swal({
              title: 'Atenção!',
              text: 'Cadastrado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$refs['modal-parametro-documento'].hide()
              }
            })
          })
          .catch((error) => {})
      },
      excluirDocumento(item) {
        useJwt
          .delete(`cadastro/instituto/excluirDocumento`, item)
          .then((response) => {
            this.carregarParametroCheckList()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      novoDocumento() {
        this.$refs.formNovoDocumento.validate().then((success) => {
          if (success) {
            if (this.dadosNovoDocumento.id == undefined) {
              jwt
                .post(`cadastro/instituto/novoDocumento`, {
                  ...this.dadosNovoDocumento,
                  institutoId: this.userData.institutoSelecionado,
                })
                .then((response) => {
                  this.dadosNovoDocumento = {}
                  this.carregarParametroCheckList()
                })
                .catch((error) => {
                  console.error(error)
                })
            } else {
              useJwt
                .update(`cadastro/instituto/updateDocumento`, this.dadosNovoDocumento.id, this.dadosNovoDocumento)
                .then((result) => {
                  this.dadosNovoDocumento = {}
                  this.carregarParametroCheckList()
                })
                .catch((error) => {
                  console.error(error)
                })
            }
          }
        })
      },
      limparNovoDocumento() {
        this.dadosNovoDocumento = {}
      },
      editarDocumento(item) {
        this.dadosNovoDocumento = item
      },
    },
  }
</script>
<style>
  @media (min-width: 992px) {
    #modal-parametro .modal-dialog {
      max-width: 1200px;
    }
  }
</style>
